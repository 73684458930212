import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, BlogHeader } from 'components';

const IS_BROWSER = typeof window !== 'undefined';

const BlogPost = props => {
  const { location, data: staticData } = props;
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicBlogPost: blogpost } = data;
  const { data: pageData } = blogpost;
  const { body: sliceData, meta_title: metaTitle, meta_description: metaDesc } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
  };

  return (
    <Layout location={location} seo={seo}>
      <BlogHeader data={pageData} />
      {sliceData.map(slice => (
        <Slice key={slice.id} data={slice} location={location} />
      ))}
    </Layout>
  );
};

export default BlogPost;

export const BlogPostQuery = graphql`
  query BlogPostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        blog_post_title {
          text
        }
        hero_background_color
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        post_excerpt {
          text
        }
        body {
          ...BlogPostWysiwyg
          ...BlogPostGridOneCol
          ...BlogPostFeaturedPosts
          ...BlogPostPlain
        }
      }
    }
  }
`;
